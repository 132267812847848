import { Link } from "react-router-dom";

import Himadri from "../asserts/home/Dr_Himadri.png";
import Ashwathi from "../asserts/home/Dr_ashwathi.png";

function MeetOurExperts() {
  return (
    <section className="2xl:h-[767px] w-screen bg-[#FFFDF9] px-4 py-6 flex flex-col gap-6 items-center sm:p-[68px_113px_68px_113px] sm:gap-[60px] 2xl:p-0 2xl:py-16">
      <h1 className="text-[#004135] text-[32px] leading-[40px] font-medium sm:text-[54px] sm:font-bold sm:leading-[72px] 2xl:text-[54px]">
        Meet <span className="text-[#228573]">our experts </span>
      </h1>

      {/* large screen view */}
      <div className="grid grid-cols-1 w-[330px] mx-auto gap-6 sm:w-[609px] sm:gap-[34px] sm:pt-6 2xl:flex 2xl:w-[85.97%] 2xl:mx-0 2xl:gap-[20px]">
        <div className="max-sm:hidden bg-white h-[359px] w-[609px] border-[#CACACA] border rounded-xl shadow-experts-card pt-[24px] sm:grid sm:gap-[34px]">
          <div className="flex flex-cols-3 gap-6 h-[215px] w-full px-4">
            <div>
              <img src={Himadri} alt="expert 1" />
            </div>

            <div className="flex flex-col sm:gap-4 h-[204px] w-[277px]">
              <div className="grid gap-[6px]">
                <h1 className="text-[22px] leading-[28px] font-bold">
                  Dr. Himadri Chaudary
                </h1>
                <div className="flex flex-row">
                  <p className="text-[#838383] text-sm font-medium">
                    Ayurveda Practitioner
                  </p>
                  <p className="text-[#838383] text-sm font-medium">
                    (BAMS, MD)
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="grid gap-[6px]">
                  <div className="flex flex-cols-3 gap-[6px]">
                    <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full text-sm font-medium">
                      Yoga Expert
                    </p>
                    <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full text-sm font-medium">
                      Thyroid
                    </p>
                    <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full text-sm font-medium underline">
                      more
                    </p>
                  </div>

                  <div className="flex flex-cols-3 gap-[6px]">
                    <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sm text-sky-900 font-medium">
                      Malayalam
                    </p>
                    <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sm text-sky-900 font-medium">
                      Hindi
                    </p>
                    <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sm text-sky-900 font-medium">
                      English
                    </p>
                  </div>
                </div>

                <div className="h-[46px] w-[227px] rounded-xl border-[#C4C4C4] border px-[11px] py-[5px]">
                  <p className="text-base text-[#5D5D5D] font-semibold text-center py-1 whitespace-nowrap">
                    Consultation Fee:{" "}
                    <span className="text-[#228573]">₹400</span>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <p className="flex justify-end gap-1 text-base font-medium">
                Online
                <span>
                  <svg
                    className="relative mt-2"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="6" fill="#43A08D" />
                  </svg>
                </span>
              </p>
              <p className="flex justify-end gap-1 text-base font-medium">
                In-clinic
                <span>
                  <svg
                    className="relative mt-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.33337 4.66665H8.66671V5.99998H7.33337V4.66665ZM7.33337 7.33331H8.66671V11.3333H7.33337V7.33331ZM8.00004 1.33331C4.32004 1.33331 1.33337 4.31998 1.33337 7.99998C1.33337 11.68 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 8.00004 1.33331ZM8.00004 13.3333C5.06004 13.3333 2.66671 10.94 2.66671 7.99998C2.66671 5.05998 5.06004 2.66665 8.00004 2.66665C10.94 2.66665 13.3334 5.05998 13.3334 7.99998C13.3334 10.94 10.94 13.3333 8.00004 13.3333Z"
                      fill="#BA1A1A"
                    />
                  </svg>
                </span>
              </p>
            </div>
          </div>

          <div className="h-[86px] w-[609px] rounded-b-xl gap-[107px] px-[21px] py-[13px] bg-[#F5F5F5] border-x border-b border-[#CACACA]">
            <div className="flex justify-between">
              <div className="flex flex-col justify-center items-end gap-[6px]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-lg text-[#218C7B] font-bold">4.9</h3>
                  <div className="flex justify-start gap-1">
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-[#737373] text-base font-normal text-center">
                  Based on 45 reviews
                </p>
              </div>

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfd_advYvwN5LPlJNQ2W7evaawhgznBoJufKrNgSgTy5AqQqw/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="h-[55px] w-[291px] bg-[#228573] px-[72.5px] py-[15.5px] text-base text-white font-semibold rounded-full">
                  Book Appointment
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="max-sm:hidden bg-white h-[359px] w-[609px] border-[#CACACA] border rounded-xl shadow-experts-card pt-[24px] sm:grid sm:gap-[34px]">
          <div className="flex flex-cols-3 gap-6 h-[215px] w-full px-4">
            <div>
              <img src={Ashwathi} alt="expert 1" />
            </div>

            <div className="flex flex-col sm:gap-4 gap-[15px] h-[204px] w-[277px]">
              <div className="grid gap-[6px]">
                <h1 className="text-[22px] leading-[28px] font-bold">
                  Dr. Aswathi A. L.
                </h1>
                <div className="flex flex-row">
                  <p className="text-[#838383] text-sm font-medium">
                    Ayurveda Practitioner
                  </p>
                  <p className="text-[#838383] text-sm font-medium">
                    (BAMS, MD)
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <div className="grid gap-[6px]">
                  <div className="flex flex-cols-3 gap-[6px]">
                    <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full text-sm font-medium">
                      Yoga Expert
                    </p>
                    <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full text-sm font-medium">
                      Thyroid
                    </p>
                    <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full text-sm font-medium underline">
                      more
                    </p>
                  </div>

                  <div className="flex flex-cols-3 gap-[6px]">
                    <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sm text-sky-900 font-medium">
                      Malayalam
                    </p>
                    <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sm text-sky-900 font-medium">
                      Hindi
                    </p>
                    <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sm text-sky-900 font-medium">
                      English
                    </p>
                  </div>
                </div>

                <div className="h-[46px] w-[227px] rounded-xl border-[#C4C4C4] border px-[11px] py-[5px]">
                  <p className="text-base text-[#5D5D5D] font-semibold text-center py-1 whitespace-nowrap">
                    Consultation Fee:{" "}
                    <span className="text-[#228573]">₹400</span>
                  </p>
                </div>
              </div>
            </div>

            <div>
              <p className="flex justify-end gap-1 text-base font-medium">
                Online
                <span>
                  <svg
                    className="relative mt-2"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="6" fill="#43A08D" />
                  </svg>
                </span>
              </p>
              <p className="flex justify-end gap-1 text-base font-medium">
                In-clinic
                <span>
                  <svg
                    className="relative mt-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.33337 4.66665H8.66671V5.99998H7.33337V4.66665ZM7.33337 7.33331H8.66671V11.3333H7.33337V7.33331ZM8.00004 1.33331C4.32004 1.33331 1.33337 4.31998 1.33337 7.99998C1.33337 11.68 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 8.00004 1.33331ZM8.00004 13.3333C5.06004 13.3333 2.66671 10.94 2.66671 7.99998C2.66671 5.05998 5.06004 2.66665 8.00004 2.66665C10.94 2.66665 13.3334 5.05998 13.3334 7.99998C13.3334 10.94 10.94 13.3333 8.00004 13.3333Z"
                      fill="#BA1A1A"
                    />
                  </svg>
                </span>
              </p>
            </div>
          </div>

          <div className="h-[86px] w-[609px] rounded-b-xl gap-[107px] px-[21px] py-[13px] bg-[#F5F5F5] border-x border-b border-[#CACACA]">
            <div className="flex justify-between">
              <div className="flex flex-col justify-center items-end gap-[6px]">
                <div className="flex gap-1 items-center">
                  <h3 className="text-lg text-[#218C7B] font-bold">4.9</h3>
                  <div className="flex justify-start gap-1">
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                    <svg
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                        fill="#F4E47F"
                      />
                    </svg>
                  </div>
                </div>
                <p className="text-[#737373] text-base font-normal text-center">
                  Based on 45 reviews
                </p>
              </div>

              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSftt5zrvos7D0i7Qb7ZwGarb9FzPNn2r8_sUFJs4YPUuYVYtQ/viewform"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="h-[55px] w-[291px] bg-[#228573] px-[72.5px] py-[15.5px] text-base text-white font-semibold rounded-full">
                  Book Appointment
                </button>
              </a>
            </div>
          </div>
        </div>

        {/* ============================ small screen View ======================================== */}

        <div
          className="sm:hidden w-[330px] grid gap-4 px-5 py-[21px] rounded-2xl border shadow-experts-card
                sm:w-[609px] sm:rounded-[18px] sm:gap-[34px]"
        >
          <div className="grid gap-3 sm:gap-6">
            <div className="flex gap-2">
              <div>
                <img src={Himadri} alt="himadri" className="w-20 h-[104px]" />
              </div>
              <div className="grid gap-2">
                <p className="text-lg font-medium">DR. Himadri Chaudary</p>
                <p className="text-xs font-medium">
                  Ayurveda Practitioner, (BAMS, MD)
                </p>

                <div className="flex gap-1 justify-center items-center">
                  <div className="flex gap-[2px] items-center">
                    <div className="flex justify-start gap-1">
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                    </div>
                  </div>
                  <p className="text-[#737373] text-xs font-medium text-center">
                    45 reviews
                  </p>
                </div>
              </div>
            </div>

            <div className="h-[32px] w-[191px] rounded-md border-[#C4C4C4] border px-[17px] py-[6px]">
              <p className="text-sm text-[#5D5D5D] font-medium text-center whitespace-nowrap">
                Consultation Fee: <span className="text-[#228573]">₹400</span>
              </p>
            </div>

            <div className="grid gap-2">
              <div className="flex flex-cols-3 gap-[6px] text-xs font-medium">
                <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full">
                  Yoga Expert
                </p>
                <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full">
                  Thyroid
                </p>
                <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full underline">
                  more
                </p>
              </div>

              <div className="flex flex-cols-3 gap-[6px] pt-[6px] text-xs font-medium">
                <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sky-900">
                  Malayalam
                </p>
                <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sky-900">
                  Hindi
                </p>
                <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sky-900">
                  English
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex justify-start items-center text-sm font-medium">
              <p className="flex justify-end items-center gap-1">
                Online
                <span>
                  <svg
                    className="relative mt-2"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="6" fill="#43A08D" />
                  </svg>
                </span>
              </p>
              <p className="flex justify-end items-center gap-1">
                In-clinic
                <span>
                  <svg
                    className="relative mt-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.33337 4.66665H8.66671V5.99998H7.33337V4.66665ZM7.33337 7.33331H8.66671V11.3333H7.33337V7.33331ZM8.00004 1.33331C4.32004 1.33331 1.33337 4.31998 1.33337 7.99998C1.33337 11.68 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 8.00004 1.33331ZM8.00004 13.3333C5.06004 13.3333 2.66671 10.94 2.66671 7.99998C2.66671 5.05998 5.06004 2.66665 8.00004 2.66665C10.94 2.66665 13.3334 5.05998 13.3334 7.99998C13.3334 10.94 10.94 13.3333 8.00004 13.3333Z"
                      fill="#BA1A1A"
                    />
                  </svg>
                </span>
              </p>
            </div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSfd_advYvwN5LPlJNQ2W7evaawhgznBoJufKrNgSgTy5AqQqw/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="h-[46px] w-[280px] bg-[#228573] text-sm text-white font-medium rounded-full my-[15.5px]">
                Book Appointment
              </button>
            </a>
          </div>
        </div>

        <div className="sm:hidden w-[330px] grid gap-4 px-5 py-[21px] rounded-2xl shadow-experts-card">
          <div className="grid gap-3">
            <div className="flex gap-2">
              <div>
                <img src={Ashwathi} alt="Ashwathi" className="w-20 h-[104px]" />
              </div>
              <div className="grid gap-2">
                <p className="text-lg font-medium">Dr. Aswathi A. L.</p>
                <p className="text-xs font-medium">
                  Ayurveda Practitioner, (BAMS, MD)
                </p>

                <div className="flex gap-1 justify-center items-center">
                  <div className="flex gap-[2px] items-center">
                    <div className="flex justify-start gap-1">
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                          fill="#F4E47F"
                        />
                      </svg>
                    </div>
                  </div>
                  <p className="text-[#737373] text-xs font-medium text-center">
                    45 reviews
                  </p>
                </div>
              </div>
            </div>

            <div className="h-[32px] w-[191px] rounded-md border-[#C4C4C4] border px-[17px] py-[6px]">
              <p className="text-sm text-[#5D5D5D] font-medium text-center whitespace-nowrap">
                Consultation Fee: <span className="text-[#228573]">₹400</span>
              </p>
            </div>

            <div className="grid gap-2">
              <div className="flex flex-cols-3 gap-[6px] text-xs font-medium">
                <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full">
                  Yoga Expert
                </p>
                <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full">
                  Thyroid
                </p>
                <p className="bg-[#E5FFF6] px-4 py-[6px] rounded-full underline">
                  more
                </p>
              </div>

              <div className="flex flex-cols-3 gap-[6px] pt-[6px] text-xs font-medium">
                <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sky-900">
                  Malayalam
                </p>
                <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sky-900">
                  Hindi
                </p>
                <p className="bg-[#DAE2FF] px-4 py-[6px] rounded-full text-sky-900">
                  English
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="flex justify-start items-center text-sm font-medium">
              <p className="flex justify-end items-center gap-1">
                Online
                <span>
                  <svg
                    className="relative mt-2"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="6" cy="6" r="6" fill="#43A08D" />
                  </svg>
                </span>
              </p>
              <p className="flex justify-end items-center gap-1">
                In-clinic
                <span>
                  <svg
                    className="relative mt-1"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.33337 4.66665H8.66671V5.99998H7.33337V4.66665ZM7.33337 7.33331H8.66671V11.3333H7.33337V7.33331ZM8.00004 1.33331C4.32004 1.33331 1.33337 4.31998 1.33337 7.99998C1.33337 11.68 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.68 14.6667 7.99998C14.6667 4.31998 11.68 1.33331 8.00004 1.33331ZM8.00004 13.3333C5.06004 13.3333 2.66671 10.94 2.66671 7.99998C2.66671 5.05998 5.06004 2.66665 8.00004 2.66665C10.94 2.66665 13.3334 5.05998 13.3334 7.99998C13.3334 10.94 10.94 13.3333 8.00004 13.3333Z"
                      fill="#BA1A1A"
                    />
                  </svg>
                </span>
              </p>
            </div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSftt5zrvos7D0i7Qb7ZwGarb9FzPNn2r8_sUFJs4YPUuYVYtQ/viewform"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="h-[46px] w-[280px] bg-[#228573] text-sm text-white font-medium rounded-full my-[15.5px]">
                Book Appointment
              </button>
            </a>
          </div>
        </div>
      </div>

      <Link
        to="/doctors"
        className="border border-[#228573] px-[74px] py-3 text-lg text-[#228573] font-medium rounded-[59px] sm:py-[26px] sm:text-[22px]"
      >
        View more doctors
      </Link>
    </section>
  );
}

export default MeetOurExperts;
